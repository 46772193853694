import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, defaultProjectHash, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useModal } from '../../../hooks/Modal';
import { useConfiguration } from '../../../hooks/Configuration';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash = configuration?.current_event_id_response, productId } = useParams<IParams>();

  const [emailRecord, setEmailRecord] = useState('');

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const { addModal } = useModal();
  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('finished');


  const [info, setInfo] = useState({
    nome: '',
    fantasia: '',
    uf_empresa: '',
    municipio_empresa: ''
  })

  const verifyCNPJ = async (cnpj) => {

    const check = cnpj.toString().replace(/[^0-9]/g, '');

    if (check?.length < 14) {
      return;
    }

    try {
      const checkCNPJ = await api.post('/check-cnpj', { cnpj: cnpj });

      setInfo({
        nome: checkCNPJ?.data?.nome,
        fantasia: checkCNPJ?.data?.fantasia,
        uf_empresa: checkCNPJ?.data?.uf_empresa,
        municipio_empresa: checkCNPJ?.data?.municipio_empresa

      })
    }
    catch (err) {

    }


  }



  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLoginUpdate = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';
      const shape = {

      };

      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;


          x++;

        })


      }


      const schema = Yup.object().shape(shape);



      await schema.validate(data, { abortEarly: false });

      const content: Record<string, any> = {
        ...data,
        email: emailRecord,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        product: product._id.toString(),
        fields: { ...fieldsValues },
        fields_text: fieldsText,
      };



      const newUser = await api
        .post('/signup-update', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';

    data.extra_3 = data?.extra_3?.[0] ? data?.extra_3?.[0] : 'Não';

    let razao_social = '';
    let fantasia = '';
    let uf_empresa = '';
    let municipio_empresa = '';

    if (data?.company_document_number) {

      try {
        const checkCNPJ = await api.post('/check-cnpj', { cnpj: data?.company_document_number });
        razao_social = checkCNPJ?.data?.nome;
        fantasia = checkCNPJ?.data?.fantasia;
        uf_empresa = checkCNPJ?.data?.uf_empresa;
        municipio_empresa = checkCNPJ?.data?.municipio_empresa;
      }
      catch (err) {

        addModal({
          title: '', content: <><h2>Informe um CNPJ válido</h2>
          </>, theme: 'whiteModalMini'
        })
        setLoading(false)
        return;
      }


    }


    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }

    if (countryISO === 'BR') {
      const validCPF = testCPF(data?.document_number);

      if (!validCPF) {
        addToast({ type: 'error', title: 'Preencha um CPF válido' })
        setLoading(false);
        return;
      }
    }


    try {
      formRef.current?.setErrors({});


      const shape = {
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
        category_id: Yup.string().required('A área é obrigatório'),
        position: Yup.string().required('O Profissão é obrigatório'),
        company: Yup.string().required('Campo Empresa é obrigatório'),
        document_number: Yup.string().required('Campo CPF é obrigatório'),

      };




      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = Yup.object().shape(shape);
      data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');












      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: ['Aceito'],
        fields: { ...fieldsValues },
        fields_text: fieldsText,
        extra_1: razao_social,
        extra_2: fantasia,
        product: '66033843a24c83725c4c032f',
        extra_4: uf_empresa,
        extra_5: municipio_empresa,
      };

      if (productId) {
        //content.product = productId;
      }


      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);
      window?.scrollTo(0, 0);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : '', product: productId ? productId : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';

    try {
      const checkUser = await api.post(`/check-subscriber`, { email: data.email, project_id: projectHash, event_id: eventHash || '', product: productId ? productId : '' });

      if (checkUser) {
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');
        if (checkUser?.data?.product?._id) {

          setProduct(checkUser?.data?.product);
        }
        setEmailRecord(data.email)
        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      return
    }


  }





  if (statusConfirmation === 'finished') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Inscrições encerradas</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Agradecemos seu interesse, mas as vagas estão encerradas</p>

        <Link to={`/`}><button className="defaultButton" type="button">
          Voltar
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Olá participante!</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.</p>

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          Acessar
        </button></Link>
      </>
    )
  }

  /*  if (window?.location?.pathname === '//') {
     return <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       <button className="blueButtonBig" type="submit">
         INSCRIÇÕES SUSPENSAS
 
 
       </button>
     </aside>
   }
   */




  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      {!success ? <p className='color-white' style={{ textAlign: 'left', marginBottom: '20px', color: 'rgb(8, 40, 81)', fontSize: '16px' }}>
        <strong>Para seguir com sua inscrição</strong><br /> e participar do evento de maneira<br /> online, preencha o cadastro abaixo.
      </p> : <></>}

      <div className='full-width' style={{ background: '#fff', width: '100%', maxWidth: '400px', borderRadius: '15px', padding: '20px', margin: '0px auto' }}>

        <aside>
          {success ? (
            <ResponseMessage
              active
              type="clean"
              title=""
              description={
                <p style={{ color: 'rgb(0, 14, 64)' }}>
                  <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Cadastro realizado com sucesso!</h2>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to={`/app/${projectHash}/login/`} >
                      <button className="buttonBigGreen" type="button">
                        Acessar
                      </button>
                    </Link>
                  </div>
                  <br />
                  Aproveite e indique para seus amigos!
                  <Whatsapp
                    text={`Olá! Acabei de me inscrever no SESI Conecta Saúde 2024. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: https://conectasaudesesi.com.br`}
                    number=""
                    message="Compartilhe!"
                  />
                </p>
              }
            />
          ) : (<>



            <Form name={projectHash} ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>



              <Input name="name" placeholder="Nome completo" />
              <Input name="email" placeholder="E-mail" />
              {countryISO === 'BR' ? <MaskedInput
                mask="(99) 999999999"
                name="phone"
                placeholder="Telefone com DDD"
              /> : <Input name="phone" placeholder={'Telefone com DDD'} />}

              {countryISO === 'BR' ? <MaskedInput
                mask={'999.999.999-99'}
                name="document_number"
                placeholder={'CPF'}
              /> : <Input
                name="document_number"
                placeholder={'CPF'}
              />}



              <Input
                name="company"
                placeholder="Empresa"
              />

              <SelectSimple
                name="category_id"
                label="Área"
                options={[

                  { label: "Administrativo", value: "Administrativo", text: "Administrativo" },
                  { label: "Comercial", value: "Comercial", text: "Comercial" },
                  { label: "Departamento de pessoas", value: "Departamento de pessoas", text: "Departamento de pessoas" },
                  { label: "Diretor(a)/Sócio(a)", value: "Diretor(a)/Sócio(a)", text: "Diretor(a)/Sócio(a)" },

                  { label: "Educação", value: "Educação", text: "Educação" },
                  { label: "Financeiro", value: "Financeiro", text: "Financeiro" },
                  { label: "Governo", value: "Governo", text: "Governo" },

                  { label: "Jurídico", value: "Jurídico", text: "Jurídico" },
                  { label: "Marketing", value: "Marketing", text: "Marketing" },
                  { label: "Operacional/Produção", value: "Operacional/Produção", text: "Operacional/Produção" },

                  { label: "Recursos humanos", value: "Recursos humanos", text: "Recursos humanos" },
                  { label: "Segurança do Trabalho", value: "Segurança do Trabalho", text: "Segurança do Trabalho" },

                  { label: "TI", value: "TI", text: "TI" },

                  { label: "Outros", value: "Outros", text: "Outros" },



                ]}
              />

              <SelectSimple
                name="position"
                label="profissão"
                options={[
                  { label: "Advogado(a)", value: "Advogado(a)", text: "Advogado(a)" },
                  { label: "Analista/Consultor(a)", value: "Analista/Consultor(a)", text: "Analista/Consultor(a)" },
                  { label: "Assistente/Auxiliar", value: "Assistente/Auxiliar", text: "Assistente/Auxiliar" },

                  { label: "Auditor(a)/Fiscal", value: "Auditor(a)/Fiscal", text: "Auditor(a)/Fiscal" },

                  { label: "Dentista", value: "Dentista", text: "Dentista" },

                  { label: "Diretor(a)/Sócio(a)", value: "Diretor(a)/Sócio(a)", text: "Diretor(a)/Sócio(a)" },

                  { label: "Enfermeiro(a)", value: "Enfermeiro(a)", text: "Enfermeiro(a)" },
                  { label: "Engenheiro(a)", value: "Engenheiro(a)", text: "Engenheiro(a)" },
                  { label: "Estagiário(a)", value: "Estagiário(a)", text: "Estagiário(a)" },

                  { label: "Estudante", value: "Estudante", text: "Estudante" },

                  { label: "Farmacêutico(a)", value: "Farmacêutico(a)", text: "Farmacêutico(a)" },
                  { label: "Fisioterapeuta", value: "Fisioterapeuta", text: "Fisioterapeuta" },
                  { label: "Fonoaudiólogo(a)", value: "Fonoaudiólogo(a)", text: "Fonoaudiólogo(a)" },


                  { label: "Gestor(a)/Gerente", value: "Gestor(a)/Gerente", text: "Gestor(a)/Gerente" },

                  { label: "Jornalista", value: "Jornalista", text: "Jornalista" },
                  { label: "Médico(a)", value: "Médico(a)", text: "Médico(a)" },
                  { label: "Nutricionista", value: "Nutricionista", text: "Nutricionista" },
                  { label: "Professor(a)/Pedagogo(a)", value: "Professor(a)/Pedagogo(a)", text: "Professor(a)/Pedagogo(a)" },

                  { label: "Psicólogo(a)", value: "Psicólogo(a)", text: "Psicólogo(a)" },

                  { label: "Servidor(a) Público(a)", value: "Servidor(a) Público(a)", text: "Servidor(a) Público(a)" },
                  { label: "Supervisor(a)/Especialista", value: "Supervisor(a)/Especialista", text: "Supervisor(a)/Especialista" },

                  { label: "Técnico(a) Operacional", value: "Técnico(a) Operacional", text: "Técnico(a) Operacional" },

                  { label: "Vendedor(a)", value: "Vendedor(a)", text: "Vendedor(a)" },



                  { label: "Outros", value: "Outros", text: "Outros" },

                ]}
              />






              <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

              {countryISO === 'BR' ? <SelectSimple setCallback={(value) => {
                setStateValue(value);
              }} options={states} name="state" label="Estado" /> :

                <Input name="state" placeholder={'Província / Região'} />}








              {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}
              <div className='greenDiv' style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
                <CheckboxInput
                  style={{ display: 'flex' }}
                  name="extra_3"


                  options={[
                    {
                      id: `Caso haja disponibilidade, gostaria de ser convidado para o evento presencial`,
                      label: 'Caso haja disponibilidade, gostaria de ser convidado para o evento presencial',
                      value: `Sim`,
                    },

                  ]}
                />
              </div>

              {countryISO === 'BR' ? <MaskedInput
                setCallback={(value) => verifyCNPJ(value)}
                mask="99.999.999/9999-99"
                name="company_document_number"
                placeholder={`${translate('CNPJ - Não obrigatório')}`}
              /> :
                <Input name="company_document_number"
                  placeholder={`${translate('Nº de identificação - Não obrigatório')}`} />}


              {info?.nome ? <div style={{
                width: '100%', background: '#F1F1F1', color: '#333', display: 'flex', flexDirection: 'column',
                alignItems: 'flex-start', justifyContent: 'flex-start', padding: '15px'
              }}>
                <p style={{ fontSize: '12px', color: '#333' }}>Razão social:</p>
                <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '12px' }} className="color2">{info?.nome}</p>
              </div> : <></>}

              {info?.fantasia ? <div style={{
                marginTop: '10px',
                width: '100%', background: '#F1F1F1', color: '#333', display: 'flex', flexDirection: 'column',
                alignItems: 'flex-start', justifyContent: 'flex-start', padding: '15px'
              }}>
                <p style={{ fontSize: '12px', color: '#333' }}>Nome fantasia:</p>
                <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '12px' }} className="color2">{info?.fantasia}</p>
              </div> : <></>}

              {info?.uf_empresa ? <div style={{
                marginTop: '10px',
                width: '100%', background: '#F1F1F1', color: '#333', display: 'flex', flexDirection: 'column',
                alignItems: 'flex-start', justifyContent: 'flex-start', padding: '15px'
              }}>
                <p style={{ fontSize: '12px', color: '#333' }}>Cidade / Estado</p>
                <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '12px' }} className="color2">{info?.municipio_empresa} / {info?.uf_empresa}</p>
              </div> : <></>}

              <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>


                <p
                  style={{
                    padding: '10px',
                    background: 'none',
                    margin: '5px auto',
                    color: '#333',
                    fontSize: '12px',
                    textAlign: 'left',
                  }}
                >

                  Fico ciente de que, ao realizar a inscrição no SESI Conecta Saúde, estarei autorizando ao SESI
                  utilizar meus dados pessoais para o cumprimento da finalidade da coleta, ou seja, viabilizar a
                  participação no evento, conforme sua Política de Privacidade, disponível neste site, bem como
                  para gestão de relacionamento, marketing e divulgação do SESI Conecta Saúde 2025.<br /><br />
                  Autorizo ainda o uso da minha imagem, captada durante a realização do evento,
                  exclusivamente para a divulgação da repercussão do SESI Conecta Saúde 2024 e campanhas
                  promocionais para a edição de 2025.

                  <br /><br />


                  {'Ao me cadastrar, afirmo que li a'}
                  {' '}
                  <strong>
                    <a
                      style={{ display: 'inline', color: '#333' }}
                      href="https://www.sesirs.org.br/politica-de-privacidade-sesi-rs"
                      target="_BLANK"
                    >
                      {'Política de Privacidade'}
                      {' '}
                    </a>
                    {' '}
                  </strong>
                  {'desta plataforma.'}
                </p>
              </div>
              <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

              </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button className="blueButtonBig" type="submit">
                  QUERO ME INSCREVER


                </button>
              </aside>
            </Form>

          </>
          )}
        </aside>
      </div>
    </>
  );
};

export default FormContainer;
