import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';
import themeDefault from './ThemeComponents';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: MontserratMedium;
    src: url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: MontserratSemiBold;
    src: url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
}

html{
  width: 100%;
  min-height: 100vh !important;


}

.whiteModal { 
  p,span,h3, strong, h2, h1, h4, nav, aside {
    color: #333 !important;
  }
}

 

body {
 background: url('/assets/Fundo.png') no-repeat center;
background-size: cover ;
background-attachment: fixed;
 

>iframe {
  position: relative !important;
  display: none ;
}

.capa-no-padding{
  padding: 0px !important ;
}

.midTitle{
  font-size: 34px !important;

  @media (max-width: 750px) {
    font-size: 24px !important;
  }
}

.midTitle2{
  font-size: 20px !important;
}

.text-center{
  text-align:center !important;
}



.color1{
  color: #07667C !important;
}

.color2{
  color: #14A483 !important;
}

.color-white{
  color: #fff !important;
}

.full-width{
  width: 100% ;
}

.row{
  display: flex ;
  flex-direction: row !important;
  justify-content: center;
}

.row-to-column{
  display: flex ;
  flex-direction: row !important;
  justify-content: center;


  @media (max-width: 750px) {
    display: flex ;
  flex-direction: column !important;
  }
}

.column{
  display: flex ;
  flex-direction: column !important;
}

.column-start{
  align-items: flex-start  !important;
}

.border1{
  border-top: 2px solid #07667C;
  border-bottom: 2px solid #07667C;
  padding: 20px 0px;
}

.gap-sm{
  gap: 20px;
}

.header-sub{
 
  display: flex ;
  flex-direction: row !important;
  justify-content: center;


  @media (max-width: 750px) {
    display: flex ;
  flex-direction: column-reverse !important;

  justify-content:center;
  align-items: center;

  img{
    max-width: 250px;
  }

  >div{
    max-width: 250px !important;
    margin-top: 25px;
  }

  }
 
}

.p-title{
    font-size: 44px !important;


  @media (max-width: 750px) {
    font-size: 16px !important;
  }
    
  }

.capa{
  p, strong{
    font-size: 16px ;
  }

  .p-title{
    font-size: 44px !important;


  @media (max-width: 750px) {
    font-size: 24px !important;
  }
    
  }
}

.greenButton2Mini{
  padding: 5px;
  font-size: 12px;
  background: #ddd;
  color: #333;
  border: 0px;
  border-radius: 5px;
}

.medium{
  font-family:  'MontserratMedium',Helvetica,  sans-serif;
}

.semibold{
  font-family:  'MontserratSemiBold',Helvetica,  sans-serif;
}
.bold{
  font-family:  'MontserratBold',Helvetica,  sans-serif;
}

.space-between {
  display: flex ;
  flex-direction: row !important;
  justify-content:space-between  !important;
  p,h2{
    width: auto !important;
  }
}

.fundoBlue{
  background: #06496E ;
}

.gradient1{
  background: rgb(6,73,110);
background: linear-gradient(180deg, rgba(6,73,110,1) 0%, rgba(30,34,68,1) 100%);
}

.gradient2{
  background: rgb(20,164,131);
background: linear-gradient(169deg, rgba(20,164,131,1) 0%, rgba(7,102,124,1) 100%);
}

.gradient3{
  background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(241,241,241,1) 100%);

}

.primaryColor{
  color: #0c4a57 !important;
}
.no-padding{
  padding: 0px !important;
}

.primaryBackground{
  background: #0c4a57;
  color: #fff;
}

.inverseBackground{
  background: #fff;
  color: #0c4a57;
}

.addBorderOnHover{
  &:hover{
  border: 2px solid #fff;
  }
}

.scaleOnHover{
  transition: 0.3s all ;
  &:hover{
  transform: scale(1.1) ;
}
}

.pattern{
  background: linear-gradient(45deg, #000000 0%, #000000 5%,transparent 5%, transparent 10%, #000000 10%, #000000 15%,transparent 15%,transparent 20%, #000000 20%, #000000 25%,transparent 25%,transparent 30%, #000000 30%, #000000 35%,transparent 35%,transparent 40%, #000000 40%, #000000 45%,transparent 45%,transparent 50%, #000000 50%, #000000 55%,transparent 55%,transparent 60%, #000000 60%, #000000 65%,transparent 65%,transparent 70%, #000000 70%, #000000 75%,transparent 70%,transparent 80%, #000000 80%, #000000 85%,transparent 85%,transparent 90%, #000000 90%, #000000 95%,transparent 95%);
        background-size: 3em 3em;
        background-color: #ffffff;
        opacity: 0.2
}

.mainCapa{
  font-size: 32px !important;
  display: inline-block ;
  margin: 0px !important;
  width: auto !important;

  @media (max-width: 750px) {
    font-size: 20px !important;
  }

}

.mainSubCapa{
  font-size: 24px;

  @media (max-width: 750px) {
    font-size: 16px !important;
  }
}

.greenDiv{
  background: #1c9284 !important;

  strong{
    color: #fff !important;
  }
}


.fundo1{
  background: url('/assets/Fundo.png') no-repeat center !important;
background-size: cover !important ;
background-attachment: fixed !important;
}

.fundo2{
  background: url('/assets/Fundo2.png') no-repeat center !important;
background-size: cover !important ;
background-attachment: fixed !important;
}

.fundo4{
  background: url('/apoio/fundo4.jpg') no-repeat center !important;
background-size: cover !important ;
background-attachment: fixed !important;
}

.fundo5{
  background: url('/apoio/bg2.jpeg') no-repeat center !important;
background-size: cover !important ;
background-attachment: fixed !important;
}


.greenButtonBig{
  padding: 17.5px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
  display: flex ;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 0px;
  background: #14A483 ;
  color: #fff;
 
}

.blueButtonBig{
  padding: 15px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
  display: flex ;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 0px;
  background: #06496E ;
  color: #fff;
 
}


.header {
   // padding: 25px 50px !important;
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 50px;
    min-width: 50%;
    @media (max-width: 1200px) {
      padding: 20px !important;
        flex-direction: column;
        gap: 30px;
        align-items: center !important;
    justify-content: center !important;
  }

    aside {
      display: inline-block;
    }

    img{
      width: 100%;
      max-width: 650px;
    }

    img.mapicon{
      width: 40px;

      @media (max-width: 750px) {
        width: 30px;
  }
    }

    p.mapiconP{
      color: #5b5c65;
      font-size: 18px;

      @media (max-width: 750px) {
        font-size: 16px;
  }
    }

    h2{
      max-width: 600px;
      font-size: 42px;
      text-align: left;
      width: 100% ;
      @media (max-width: 750px) {
         font-size: 34px;
  }
     
      @media (max-width: 750px) {
        font-size: 34px;
  }
    }


    strong.green{
      font-size: 38px;
      color: #139b61;

      @media (max-width: 750px) {
         font-size: 24px;
  }
    }

    strong.gray{
      font-size: 38px;
      color: #5b5c65;

      @media (max-width: 750px) {
         font-size: 24px;
  }
    }



    p{
      font-size: 14px;
      text-align: left;
      width: 100% ;

      @media (max-width: 750px) {
        font-size: 18px;
  }
    }

    
  }


.toUpperCase{
  text-transform: uppercase;
}

  .header.headerLeft{
    padding: 0px 50px !important;
    min-width: 50%;

    @media (max-width: 750px) {
      padding: 0px !important;
    }
  }



.shadowButtonSelect{
  background: #efefef ;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 15px ;
  display: flex ;
  justify-content: center;
 
  width: 100%;
  transition: 0.5s all ;

  &:hover{
    background: #ddd;
  }
  
}

.yellowBallon{

position: absolute ;
top: 100px;
left: 30px;
width: 100px;
height: 100px;
background: #fcf214 ;
display: flex !important ;
align-items: center;
justify-content: center;
border-radius: 50%;
transform: rotate(-45deg);
color: #881d0b;
font-weight: bold;
text-align: center;
box-shadow: 2px 2px 10px rgba(0,0,0,0.7);

@media (max-width: 750px) {
  top: 70px;
left: 30px;
width: 70px;
height: 70px;
z-index: 100;
font-size: 11px;
  }

}

.yellowBallon2{

position: absolute ;
top: -60px;
left: -60px;
width: 100px;
height: 100px;
background: #fcf214 ;
display: flex !important ;
align-items: center;
justify-content: center;
border-radius: 50%;
transform: rotate(-25deg);
color: #881d0b;
font-weight: bold;
text-align: center;
box-shadow: 2px 2px 10px rgba(0,0,0,0.7);

@media (max-width: 750px) {
  top: -60px;
left: 0px;
width: 70px;
height: 70px;
z-index: 100;
font-size: 11px;
  }

}


.yellowBallon3{

position: absolute ;
top: -70px;
left: 50%;
width: 130px;
 transform: translateX(-50%);
 
display: flex !important ;
align-items: center;
justify-content: center;
border-radius: 50%;
 
font-weight: bold;
text-align: center;
 

@media (max-width: 750px) {
display: none ;
  }

}

.tableRef{
  width: 100%;
  max-width: 800px;
  background: #fff ;
    border: 2px solid #ccc;
    margin: 10px auto;
    border-collapse: collapse;

    tr.highlight{
      background: rgb(12, 148, 144);
      color: #fff;
      th{
        color: #fff !important;
        text-align: left; 
      }
    }
    td,th {
      border: 1px solid #ddd;
      padding: 10px;
      color: #333;
    }
  }

.lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

.buttonBigGreen{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 12px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
 
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
  margin-bottom: 20px;
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;

}

.buttonBigGreenIcon{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 10px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
  font-family:  'Montserrat',Helvetica,  sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
 
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  
gap: 10px;
display: flex ;
align-items: center;
justify-content: center;
 
  >svg{
    font-size: 24px;
    min-width: 20px;
    min-height: 20px;
    color: #fff;
  }

}

.greenButton2{
    padding: 20px;
    background: rgb(12, 74, 87);
    color: #fff;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    min-width: 130px;
    transition: 0.4s all;
    font-weight: bold;

    &:hover{
      transform: scale(1.15) ;
    }
  }

.titleElement{
  font-size: 28px !important;
  text-transform: uppercase;
}

.p50.socialFooter .iconSet svg{
  
    min-width: 45px !important;
    font-size: 45px !important;
 
}
 
.searchButton{
    background: rgba(0,100,0);
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 30px;
      font-size: 30px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }

  .searchButtonMini{
    background: rgba(0,100,0);
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 20px;
      font-size: 20px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }
 


  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;


 
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.tooltip{
  background: #777 ;
  color: #333;
}

.spin {
  animation: ${rotate} 2s infinite ;
}

.table{
  width: 100%;
  border-collapse: collapse;
  td{
    border: 1px solid #ddd;
    padding: 10px;
    color: #333;

  }
}


.searchDivStart2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        max-width: 1600px;
        padding: 0px 15px;
        margin: 0px auto;

        >select, input {
          min-width: 300px;
          padding: 10px;
          border-radius: 12px;
          background: #4ff ;
          border: 0px;
          margin:  10px;
        }

        button{
          padding: 10px;
          border-radius: 12px;
          background: #4ff ;
          border: 0px;

          >svg{
            font-size: 18px;
          }
        }

        @media (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
    select, input {
      width: 100%;

    }

    div{
      width: 100%;
    }
    
  }
      }

.slick-slider{
  width: 100%;

 
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color: #f15a23;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color:  #f15a23;
    font-size: 40px;
    opacity: 1 ;
  }
}

.fixedWhatsapp{
  position: fixed;
    bottom: 15px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    background: rgb(50,150,26);
    color: #fff !important;
    padding: 15px;
    border: 0px;
}

.CaptureLeads{
  min-width: 250px;
  background: rgb(241, 90, 35)  ;
  color: ${themeDefault.textComponent.title.color} ;
  padding: 10px 15px;
  border-radius: 5px;
  border: 0px;
}

.divSecoundaryBackground {
  background: ${themeDefault?.textComponent?.title.color} !important;
}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
font-family:  'Montserrat',Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'Montserrat',Helvetica,  sans-serif;
 
}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Montserrat',Helvetica,  sans-serif;
  
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 15px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color:  rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
       
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
    
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 15px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
      background: rgb(0, 15, 64)  !important;
      font-weight: bold;

      color: #fff !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
        
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #333  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #333  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini,  button.defaultButtonMiniRed,     button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64) !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }

    button.defaultButtonMiniRed{
      background: rgb(0,150,50) !important;

      &:hover {
        background: rgb(0,200,50)  !important;
      }
    }
    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      cursor: pointer;
      background: #fff !important;
      color: #333  !important;
      border-radius: 10px  !important;
      padding: 10px;
      border-radius: 10px  !important;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
       
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
  border: 1px solid #ddd  !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2)  !important;

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}
`;
